export function addAllItem () {
  return { value: "", label: "全部" };
}

export function createItems(list, type) {
  return [type === "all" ? addAllItem() : null, ...list].filter((item) => item);
}

export function createCardCloseStatusType(type) {
  return createItems(
    [
      { value: 0, label: "未关卡" },
      { value: 1, label: "已关卡" },
      { value: 2, label: "已撤销关卡" },
      { value: 3, label: "已取消" },
      { value: 4, label: "已失效" },
    ],
    type
  );
}

export function createCardOpenStatusType(type) {
  return createItems(
    [
      { value: 0, label: "未开卡" },
      { value: 1, label: "已开卡" },
      { value: 2, label: "已撤销开卡" },
      { value: 3, label: "已取消" },
      { value: 4, label: "已失效" },
    ],
    type
  );
}

export function createCardCloseType(type) {
  return createItems(
    [
      { value: 1, label: "部分关卡" },
      { value: 2, label: "整单关卡" },
    ],
    type
  );
}

export function createCardOpenType(type) {
  return createItems(
    [
      { value: 1, label: "部分开卡" },
      { value: 2, label: "整单开卡" },
    ],
    type
  );
}

export function createCardTypes (type) {
  return createItems(
    [
      { value: 1, label: "现金储值卡",shopType:1,appType:3 },
      { value: 2, label: "双选储值卡" ,shopType:6,appType:3},
      { value: 3, label: "AI智能套餐卡", shopType:5,appType:5 },
      { value: 4, label: "计次卡", shopType:7,appType:6 },
      { value: 5, label: "宅配卡" ,shopType:7,appType:7},
    ],
    type
  );    
}

export function createRefundType(type) {
  return createItems(
    [
      { value: 0, label: "未退款" },
      { value: 1, label: "部分退款" },
      { value: 2, label: "已退款" },
    ],
    type
  );
}

export function createPayType(type) {
  return createItems(
    [
      { value: 0, label: "未付款" },
      { value: 1, label: "部分付款" },
      { value: 2, label: "已付款" },
    ],
    type
  );
}

export function createTradeType(type) {
  return createItems(
    [
      { value: 0, label: "未核销" },
      { value: 1, label: "部分核销" },
      { value: 2, label: "已核销" },
      { value: 3, label: "已取消" },
    ],
    type
  );
}
