import * as TYPES from "./type";

export function getItemName (value, func) {
    let _item = TYPES[func]().find((item) => item.value === value);
  return _item ? _item.label : "";
}

export function getCardCloseStatusTypeName (value) {
  return getItemName(value, "createCardCloseStatusType");
}

export function getCardOpenStatusTypeName(value) {
  return getItemName(value, "createCardOpenStatusType");
}

export function getCardCloseTypeName(value) {
  return getItemName(value, "createCardCloseType");
}

export function getCardOpenTypeName(value) {
  return getItemName(value, "createCardOpenType");
}

export function getAppTypeName(value) {
  return getItemName(value, "createCardTypes");
}

export function getTradeTypeName (value) {
  return getItemName(value, "createTradeType");
}

export function getPayTypeName(value) {
  return getItemName(value, "createPayType");
}

export function getRefundTypeName(value) {
  return getItemName(value, "createRefundType");
}
