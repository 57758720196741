<template>
  <div style="background-color: #ffffff">
    <!-- 销售单 -->
    <div v-if="tableData.type === 1">
      <table cellpadding="0" cellspacing="0">
        <tr style="text-align: center">
          <th colspan="11">销售单</th>
        </tr>
        <tr>
          <td>应用类型</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">
            {{ getAppTypeName(tableData.app_type_shop_type_flag) }}
          </td>
        </tr>
        <tr>
          <td>客户名称</td>
          <td colspan="4">{{ tableData.client_name }}</td>
          <td>单据日期</td>
          <td colspan="3">{{ tableData.create_time | unixToDate }}</td>
          <td>销售单号</td>
          <td colspan="3">{{ tableData.trade_sn }}</td>
        </tr>
        <tr>
          <td>联系人名称</td>
          <td colspan="4">{{ tableData.link_name }}</td>
          <td>电话</td>
          <td colspan="3">{{ tableData.link_phone }}</td>
          <td>付款状态</td>
          <td colspan="3">
            {{
              tableData.pay_status === 0
              ? "未付款"
              : tableData.pay_status === 1
                ? "部分付款"
                : "已付款"
            }}
          </td>
        </tr>
        <tr>
          <td>客户地址</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">{{ tableData.client_addr }}</td>
        </tr>
        <tr class="card">
          <td>序号</td>
          <td>商城名称</td>
          <td>卡券名称</td>
          <td>单价(面值)</td>
          <td>单位</td>
          <td>始号段</td>
          <td>末号段</td>
          <td>数量</td>
          <td>折扣率</td>
          <td>优惠金额</td>
          <td>优惠后金额</td>
        </tr>
        <template v-if="tableData.card_range_list && tableData.card_range_list.length">
          <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.shop_name }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.card_value }}</td>
            <td>元</td>
            <td>{{ item.card_code_start }}</td>
            <td>{{ item.card_code_end }}</td>
            <td>{{ item.card_num }}</td>
            <td>{{ item.discount_ratio }}</td>
            <td>{{ item.discount_value }}</td>
            <td>{{ RoundNum(item.card_num * item.card_value - item.discount_value) }}</td>
          </tr>
        </template>
        <tr class="card">
          <td>合计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ tableData.card_num }}</td>
          <td></td>
          <td>
            {{
              RoundNum(tableData.total_price - tableData.after_discount_price)
            }}
          </td>
          <td>{{ tableData.after_discount_price }}</td>
        </tr>
        <tr class="remark">
          <td>备注信息</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">
            {{ tableData.remark }}
          </td>
        </tr>
      </table>
      <div style="display: flex; justify-content: space-between">
        <p style="width: 20%">业务人员：</p>
        <p style="width: 20%">制单：</p>
        <p style="width: 25%">审核：</p>
        <p style="width: 35%">签字：</p>
      </div>
    </div>
    <!-- 销售单 -->

    <!-- 销售退单 -->
    <div v-if="tableData.type === 2">
      <table cellpadding="0" cellspacing="0">
        <tr style="text-align: center">
          <th colspan="11">销售退单</th>
        </tr>
        <tr>
          <td>应用类型</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">
            {{ getAppTypeName(tableData.app_type_shop_type_flag) }}
          </td>
        </tr>
        <tr>
          <td>客户名称</td>
          <td colspan="4">{{ tableData.client_name }}</td>
          <td>单据日期</td>
          <td colspan="3">{{ tableData.create_time | unixToDate }}</td>
          <td>销售退单号</td>
          <td colspan="3">{{ tableData.sn }}</td>
        </tr>
        <tr>
          <td>联系人名称</td>
          <td colspan="4">{{ tableData.link_name }}</td>
          <td>电话</td>
          <td colspan="3">{{ tableData.link_phone }}</td>
          <td>退款状态</td>
          <td colspan="3">
            {{
              tableData.refund_status === 0
              ? "未退款"
              : tableData.refund_status === 1
                ? "部分退款"
                : "已退款"
            }}
          </td>
        </tr>
        <tr>
          <td>客户地址</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">{{ tableData.client_addr }}</td>
        </tr>
        <tr class="card">
          <td>序号</td>
          <td>商城名称</td>
          <td>卡券名称</td>
          <td>单价(面值)</td>
          <td>单位</td>
          <td>始号段</td>
          <td>末号段</td>
          <td>数量</td>
          <td>折扣率</td>
          <td>优惠金额</td>
          <td>优惠后金额</td>
        </tr>
        <template v-if="tableData.card_range_list && tableData.card_range_list.length">
          <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.shop_name }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.card_value }}</td>
            <td>元</td>
            <td>{{ item.card_code_start }}</td>
            <td>{{ item.card_code_end }}</td>
            <td>{{ item.card_num }}</td>
            <td>{{ item.discount_ratio }}</td>
            <td>{{ item.discount_value }}</td>
            <td>{{ RoundNum(item.card_num * item.card_value - item.discount_value) }}</td>
          </tr>
        </template>
        <tr class="card">
          <td>合计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ tableData.card_num }}</td>
          <td></td>
          <td>{{ RoundNum(tableData.total_price - tableData.after_discount_price) }}</td>
          <td>{{ tableData.after_discount_price }}</td>
        </tr>
        <tr class="remark">
          <td>备注信息</td>
          <td colspan="10" style="text-align: left; padding: 0 10px">
            {{ tableData.remark }}
          </td>
        </tr>
      </table>
      <div style="display: flex; justify-content: space-between">
        <p style="width: 20%">业务人员：</p>
        <p style="width: 20%">制单：</p>
        <p style="width: 25%">审核：</p>
        <p style="width: 35%">签字：</p>
      </div>
    </div>
    <!-- 销售退单 -->

    <!-- 销售关卡单 -->
    <div v-if="tableData.type === 3">
      <table cellpadding="0" cellspacing="0">
        <tr style="text-align: center">
          <th colspan="7">销售关卡单</th>
        </tr>
        <tr>
          <td>应用类型</td>
          <td colspan="6" style="text-align: left; padding: 0 10px">
            {{ getAppTypeName(tableData.app_type_shop_type_flag) }}
          </td>
        </tr>
        <tr>
          <td>客户名称</td>
          <td colspan="2">{{ tableData.client_name }}</td>
          <td>单据日期</td>
          <td>{{ tableData.create_time | unixToDate }}</td>
          <td>销售关卡单号</td>
          <td>{{ tableData.sn }}</td>
        </tr>
        <tr class="card">
          <td>序号</td>
          <td>商城名称</td>
          <td>卡券名称</td>
          <td>单价(面值)</td>
          <td>始号段</td>
          <td>末号段</td>
          <td>数量</td>
        </tr>
        <template v-if="tableData.card_range_list && tableData.card_range_list.length">
          <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.shop_name }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.card_value }}</td>
            <td>{{ item.card_code_start }}</td>
            <td>{{ item.card_code_end }}</td>
            <td>{{ item.card_num }}</td>
          </tr>
        </template>
        <tr class="card">
          <td>合计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ tableData.card_num }}</td>
        </tr>
        <tr>
          <td>备注信息</td>
          <td colspan="6" style="text-align: left; padding: 0 10px">
            {{ tableData.remark }}
          </td>
        </tr>
      </table>
      <div style="display: flex; justify-content: space-between">
        <p style="width: 33%">业务人员：</p>
        <p style="width: 33%">制单：</p>
        <p style="width: 33%">签字：</p>
      </div>
    </div>
    <!-- 销售关卡单 -->

    <!-- 销售开卡单 -->
    <div v-if="tableData.type === 4">
      <table cellpadding="0" cellspacing="0">
        <tr style="text-align: center">
          <th colspan="7">销售开卡单</th>
        </tr>
        <tr>
          <td>应用类型</td>
          <td colspan="6" style="text-align: left; padding: 0 10px">
            {{ getAppTypeName(tableData.app_type_shop_type_flag) }}
          </td>
        </tr>
        <tr>
          <td>客户名称</td>
          <td colspan="2">{{ tableData.client_name }}</td>
          <td>单据日期</td>
          <td>{{ tableData.create_time | unixToDate }}</td>
          <td>销售开卡单号</td>
          <td>{{ tableData.sn }}</td>
        </tr>
        <tr class="card">
          <td>序号</td>
          <td>商城名称</td>
          <td>卡券名称</td>
          <td>单价(面值)</td>
          <td>始号段</td>
          <td>末号段</td>
          <td>数量</td>
        </tr>
        <template v-if="tableData.card_range_list && tableData.card_range_list.length">
          <tr class="card" v-for="(item, index) in tableData.card_range_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.shop_name }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.card_value }}</td>
            <td>{{ item.card_code_start }}</td>
            <td>{{ item.card_code_end }}</td>
            <td>{{ item.card_num }}</td>
          </tr>
        </template>
        <tr class="card">
          <td>合计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ tableData.card_num }}</td>
        </tr>
        <tr>
          <td>备注信息</td>
          <td colspan="6" style="text-align: left; padding: 0 10px">
            {{ tableData.remark }}
          </td>
        </tr>
      </table>
      <div style="display: flex; justify-content: space-between">
        <p style="width: 33%">业务人员：</p>
        <p style="width: 33%">制单：</p>
        <p style="width: 33%">签字：</p>
      </div>
    </div>
    <!-- 销售关卡单 -->
  </div>
</template>

<script>
import {
  getAppTypeName,
  getRefundTypeName,
  getTradeTypeName,
  getPayTypeName,
} from "@/views/tools/sale-card-open/utils/find";

export default {
  name: "printTemplate",
  props: {
    tableData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  watch: {
    tableData: {
      deep: true,
      handler (newVal, oldVal) {
        this.$forceUpdate();
      },
    },
  },
  methods: {
    getAppTypeName (value) {
      return getAppTypeName(value);
    },
    getRefundTypeName (value) {
      return getRefundTypeName(value);
    },
    getTradeTypeName (value) {
      return getTradeTypeName(value);
    },
    getPayTypeName (value) {
      return getPayTypeName(value);
    },
    RoundNum (num, decimal = 10) {
      return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border: none;
  border-bottom: 1px solid #000;
}

table tr td {
  border: 0.5px solid #000;
  border-bottom: none;
  border-right: none;
}

table tr td:last-of-type,
table tr th:last-of-type {
  border-right: 1px solid #000;
}

table tr th {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}

table tr th:nth-child(2) {
  width: 0;
}

table {
  width: 100%;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: center;

  tr {
    height: 30px;

    td {
      padding-left: 2px;
    }
  }

  .card {
    td {
      text-align: center;
      padding-left: 0;
    }
  }

  .remark {
    height: 80px;
  }
}
</style>
